import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import CustomPopup from '../../components/Popup/custom-popup';
import Layout from '../../components/layout';
import Seo from "../../components/seo";
import { submitActiveCampaignForm, submitACPatentPCQuiz } from '../../utils/campaign';
import { saveAnswerWithEmailAndValue, saveAnswerWithNameAndValue, saveAnswerWithPhoneAndValue, validateSubscriberData } from '../../utils/utils';

const Result = (props) => {
  const childRef = useRef();
  // console.log(props)
  const canonicalUrl = `/quiz/result`;
  const seo = {};
  //
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const captchaRef = useRef(null);
  
  // 
  const inputName = (e) => {
    setName(e.target.value);
    //
    saveAnswerWithNameAndValue(e.target.value)
  }

  // 
  const inputEmail = (e) => {
    setEmail(e.target.value);
    //
    saveAnswerWithEmailAndValue(e.target.value)
  }

   const inputPhone = (e) => {
    setPhone(e.target.value);
    //
    saveAnswerWithPhoneAndValue(e.target.value)
  }

  //
  const close = () => {
    childRef.current?.close();
    // set campaign
    const data = { name: name, email: email, phone: phone };
    submitActiveCampaignForm(data, false);
    // go home
    setTimeout(() => {
      window.location.href = "/";
    }, 1500)
    
  }

  //
  const getResult = () => {
    if (!name || !email || !phone) {
      alert('Please fill in all fields.');
    } else {
      // Validate info
      const data = { name: name, email: email, phone: phone };
      if (!validateSubscriberData(data)) {
        return;
      }

      //
      const answers = localStorage?.getItem('answers');
      // submit AC
      submitACPatentPCQuiz(answers);
      // submit result
      fetch("https://api.trustyip.com/quiz-questions/answers/add", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json"
        },
        "body": answers
      })
      .then(response => response.json())
      .then(response => {
        childRef.current?.open();
        // alert(`We are generating the report, please check your email inbox (${email}) including spam folder in a few minutes.`);
      })
      .catch(err => {
        alert('There is something wrong. Please try again later!');
        console.log(err);
      });
    }
  }

  return (
    <Layout>
      <Seo title={ `Quiz Result ` } canonical={ canonicalUrl } seo={seo} />
        <section className="bg-white my-5">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
              <p class="mb-0 text-start ps-1">100% completed</p>
              <div class="progress">
                    <div class="progress-bar progress-bar-striped" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: 100 + '%' }}></div>
                </div>
                <h3 className="my-4">
                    Your results are in! Please complete the form below.
                </h3>
                <p>Don't worry, we will email your results in the next hour, please add patentpc.com to your email whitelist to get your report.</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div class="col-md-5 col-sm-12">
              <div class="mb-3">
                <label for="name" class="form-label">Your Name</label>
                <input class="form-control" id="name" name="name" required value={name} onChange={ inputName }></input>
                </div>
                <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input type="email" class="form-control" id="email" placeholder="name@example.com" required  value={email} onChange={ inputEmail }/>
                </div>
                <div class="mb-3">
                <label for="phone" class="form-label">Phone Number</label>
                <input type="text" class="form-control" id="phone" placeholder="" required  value={phone} onChange={ inputPhone }/>
                </div>
                <div class="mb-3">
                <ReCAPTCHA sitekey="6LetH-InAAAAAOJxzG4oeh59AVKhM3nj4eCxXXBI" ref={captchaRef} />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div class="col-md-3">
                <div class="d-grid gap-2">
                    <button onClick={getResult} class="btn btn-warning fw-bold py-3" type="button">Get My Results</button> 
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr/>
      <CustomPopup
        onClose={close}
        ref={childRef}
        show = {false}
        title="">
          <p className="text-center fw-bold mb-4 mt-0">
            We are generating the report, please check your email inbox ({email}) including spam folder in a few minutes.
          </p>
            <button className='btn btn-warning w-100 fw-bold' onClick={close}>Close</button>
      </CustomPopup>
    </Layout>
  )
}

export default Result;
